<template>
  <div class="auth d-flex justify-content-center align-items-center" >
    <b-container fluid="sm">
      <b-row align-content="center" align-h="center" align-v="center">
        <b-alert v-if="error" show variant="danger">Oops.. tivemos um problema com a autênticação</b-alert>
        <b-alert v-else show variant="primary">Autenticado</b-alert>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import {mapActions} from 'vuex'

export default {
  name: "LoginByID",
  data: () => ({
    error: false
  }),
  created() {
    this.get()
  },
  methods: {
    ...mapActions('auth', ['loginByID']),

    async get() {

      let user = this.$route.params.user

      if (user) {
          if (await this.loginByID({id: user})) {
            this.error = false
            this.$router.push('/events')
          } else {
            this.error = true
          }
      } else {
        this.error = true
      }
    },
  }
}
</script>

<style scoped>
  .auth {
    height: 100vh !important;
  }
</style>